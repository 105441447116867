export default {
  masterChef: {
    1666600000: '0xD0Cb3E55449646c9735D53E83EEA5Eb7e97A52Dc',
  },
  sousChef: {
    1666600000: '0x79f73e0F9D31EA1490fd90632F65638a048a2A5F',
  },
  upgradeSwap: {
    1666600000: '0x50340f0D03f8c1Ff5DD1A7c9DB1e0c2684930ED1',
  },
  oldMochi: {
    1666600000: '0x0dD740Db89B9fDA3Baadf7396DdAD702b6E8D6f5',
  },
  lotteryV2: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  multiCall: {
    1666600000: '0x627194b25Ba7b779488e9861ea17896262364438',
  },
  pancakeProfile: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  pancakeRabbits: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  bunnyFactory: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  claimRefund: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  pointCenterIfo: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  bunnySpecial: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  tradingCompetition: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  easterNft: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  cakeVault: {
    1666600000: '0x2A9BB5aCF08807B58936c83413d052F66aC31739',
  },
  predictions: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  chainlinkOracle: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  bunnySpecialCakeVault: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  bunnySpecialPrediction: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
  farmAuction: {
    1666600000: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
  },
}
